/*----------------------------------------------- --- DEVELOP URLS START ------------------------------------------------------------------------------------- */

export const LOGIN_API_BASE_URL     = "https://api.ptest.nidhi.apcfss.in/login/"
export const MASTER_API_BASE_URL    = "https://api.ptest.nidhi.apcfss.in/master/"
export const PAYROLL_API_BASE_URL   = "https://api.ptest.nidhi.apcfss.in/hrms/"

/* LOGIN UI URLS IN DEVELOP BRANCH*/
export const LOGIN_PAGE_URL         = 'https://ptest.nidhi.apcfss.in/login';
export const LOGIN_DOMAIN           = 'https://ptest.nidhi.apcfss.in';


/* HERB Modules UI URLS IN DEVELOP */

export const BUDGET_UI_URL              = 'https://budget.ptest.herb.apcfss.in';

export const BUDGET_PLANNING_UI_URL     = "https://budgetplanning.ptest.nidhi.apcfss.in"

export const DCM_UI_URL                 = "https://dcm.ptest.herb.apcfss.in"

export const ESR_UI_URL                 = "https://esr.ptest.nidhi.apcfss.in"

export const ESS_UI_URL                 = "https://ess.ptest.nidhi.apcfss.in"

export const EXPENDITURE_UI_URL         = 'https://expenditures.ptest.nidhi.apcfss.in';

export const HRMS_UI_URL                = 'https://hrms.ptest.nidhi.apcfss.in';

export const PAYROLL_UI_URL             = 'https://payroll.ptest.nidhi.apcfss.in';

export const WORKS_UI_URL               = 'https://works.ptest.nidhi.apcfss.in'


/* Other COMMON  URLS */
export const CITIZEN_BILL_STATUS_URL    = EXPENDITURE_UI_URL + '/citizenbillstatus';

export const CHANGE_PASSWORD_UI_URL     = PAYROLL_UI_URL + "/changePassword";

export const SRConfirmation             = PAYROLL_UI_URL + "/srConfirmation";

export const TIMEZONE_REDIRECTION_URL   = "https://support.yondo.com/hc/en-us/articles/206597867-How-do-I-change-my-computer-time-zone-settings-"

export const AUTHENTICATION_URL         = LOGIN_API_BASE_URL + "authenticate";

// export const USER_VERIFICATION          = LOGIN_API_BASE_URL + "getAadharOTPByLogin/";

export const USER_VERIFICATION_OTP      = LOGIN_API_BASE_URL+ "getAadharOTPByLoginId";

export const LOGIN_AUTHENTICATION_URL   = LOGIN_API_BASE_URL + "loginOTPVerification";

export const SIGN_IN_URL                = LOGIN_API_BASE_URL + 'authenticator'

export const TOKEN_INVALIDATE_URL       = LOGIN_API_BASE_URL + 'logout'

export const GENERATE_OTP_URL           = LOGIN_API_BASE_URL + "generateotp"

export const VERIFY_OTP_URL             = LOGIN_API_BASE_URL + "verifyotp";

export const SERVICES_URL               = MASTER_API_BASE_URL + "serviceslist/";

export const EMPLOYEE_COUNT             = PAYROLL_API_BASE_URL + "srcertification/get/employeescount"

export const GET_DETAILS_BY_AADHAR_DOB  = LOGIN_API_BASE_URL+"getLoginDetails/"

export const GET_RETIRING_EMP_COUNT     = PAYROLL_API_BASE_URL+"getRetiringEmpsCountInDDo"

/*----------------------------------------------- --- DEVELOP URLS END ------------------------------------------------------------------------------------- */







/* Commented URLS */
// export const GET_LOGGED_USER_NAME = 'https://api.dev.herb.apcfss.in/herb-budget-services/v1/common/employeename'