/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as HERBUI from "react-bootstrap";
import "./loginandlandingpage.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./containers/Login";
import Dashboard from "./containers/Dashboard";
import Loader from "./reducers/Loader";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import SpinnerReducers from "./reducers/SpinnerReducer";
import thunk from "redux-thunk";
import axios from "axios";
import Swal from "sweetalert2";
import { LOGIN_PAGE_URL } from "./Api/APIURLS";
import ForgotPassword from "./containers/ForgotPassword";
import Gos from "./containers/Gos";
import Footer from "./containers/Footer";
import LogOut from "./utils/LogOut";
import KnowYourCfmsId from "./containers/KnowYourCfmsId";
import SiginPage from "./containers/SiginPage";
import BudgetRelatedPdfDetails from "./containers/BudgetRelatedPdfDetails";
import LoginPageAuthentication from "./containers/LoginPage";
import UserManuals from "./containers/UserManuals";


const reducer = combineReducers({
  spinner: SpinnerReducers,
});
const store = createStore(reducer, applyMiddleware(thunk));
const { dispatch } = store;
axios.interceptors.request.use(function (config) {
  dispatch({ type: 'SHOW_SPINNER' });
  return config;
}, function (error) {
  return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
  dispatch({ type: 'HIDE_SPINNER' });
  return response;
}, function (error) {

  return Promise.reject(error);
})
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      if (localStorage.getItem('token') !== null) {
        return <Component  ></Component>;
      }
      else {
        Swal.fire({
          text: "UnAuthorised Access",
          icon: 'error',
          confirmButtonColor: '#4962B3',
          confirmButtonText: 'Ok'
        }).then(function () {
          window.location.href = LOGIN_PAGE_URL
        });
      }
    }
    } />
  );
};

function App() {
  return (
    <>
      <Provider store={store}>
        <HERBUI.Container fluid>
          <Router>
            <Switch>
              <Route path="/login">
                <LoginPage />
                {/* <LoginPageAuthentication /> */}
              </Route>
              <Route path="/69d17eb8fccce572d0ec63e721b250a6">
                <LoginPage />
                {/* <LoginPageAuthentication /> */}
              </Route>
              <Route path="/loginPage">
                <LoginPageAuthentication />
              </Route>
              <Route path="/signin">
                <SiginPage />
              </Route>
              <PrivateRoute path="/dashboard">
                <Dashboard />
              </PrivateRoute>
              <Route path="/forgotPassword">
                <ForgotPassword />
              </Route>
              <Route path="/KnowYourCfmsId">
                <KnowYourCfmsId />
              </Route>
              <Route path="/Gos">
                <Gos />
              </Route>
              <Route path="/budgetRelatedPdfDetails">
                <BudgetRelatedPdfDetails />
              </Route>
              <Route path="/usermanuals">
                <UserManuals />
              </Route>
              <Route path="/logOut">
                <LogOut />
              </Route>
              <Route path="*">
                <LoginPage />
                {/* <LoginPageAuthentication /> */}
              </Route>
            </Switch>
          </Router>
          <Loader />
          <Footer />
        </HERBUI.Container>
      </Provider>
    </>
  );
}

export default App;
