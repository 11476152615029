/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import * as HERBUI from "react-bootstrap";
import { BiArrowFromLeft } from "react-icons/bi";
import {  LOGIN_DOMAIN } from "../Api/APIURLS";
var createGuest = require('cross-domain-storage/guest');
const DashboardCardBudget = (props) => {
  const { title, url, module } = props;
  function redirectUrl(url, module) {
    let mainAppguest = createGuest(LOGIN_DOMAIN);
    localStorage.setItem('url', JSON.stringify(url))
    mainAppguest.set('url', JSON.stringify(url), function (error, data) {
      // foo is now set to 'bar'
    });
    window.location.href = url
  }
  return (
    <HERBUI.Col xs={12} sm={12} md={3} lg={3} xl={3}>
      <div className="cards card1 bg-white mb-4">
        <div className="cards-body">
          <a onClick={() => redirectUrl(url, module)} href='javascript:void(0)'>
            <div class="go-corner"  > <div class="go-arrow"> <BiArrowFromLeft className="cardico icon-size-header2" /> </div> </div>
            <h5 className="cards_title float-start"  >{title}</h5>  </a>
        </div>
      </div>
    </HERBUI.Col>
  );
};

export default DashboardCardBudget;
