/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import * as HERBUI from "react-bootstrap";
import "../loginandlandingpage.css";
import { BiUser, BiLock } from "react-icons/bi";
import * as Yup from 'yup';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FaEye,FaEyeSlash } from 'react-icons/fa'
import {
  AUTHENTICATION_URL, BUDGET_PLANNING_UI_URL, BUDGET_UI_URL, DCM_UI_URL, ESR_UI_URL, ESS_UI_URL, EXPENDITURE_UI_URL, PAYROLL_UI_URL,
  LOGIN_DOMAIN, WORKS_UI_URL, HRMS_UI_URL
} from "../Api/APIURLS";
import { useDispatch } from "react-redux";
import { CommonAjaxPostLoginRequest } from '../Api/CommonAjaxUtils'
var createHost = require('cross-domain-storage/host');
var createGuest = require('cross-domain-storage/guest');
const Login = () => {
  var storageHost = null;
  var mainAppguest = null;
  const dispatch = useDispatch();
  const history = useHistory();
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const intialValues = { username: '', password: '' }
  const validationSchema = Yup.object({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required')
  })

  // useEffect(()=>{
  //     let systemTimeZone=Intl.DateTimeFormat().resolvedOptions().timeZone
  //     if(systemTimeZone!=='Asia/Calcutta'){
  //       Swal.fire({
  //         icon:"error",
  //         title:"Uh-oh! Timezone Error",
  //         text:"Your System Timezone is "+systemTimeZone+". Change it to Asia/Calcutta to proceed."
  //       }).then(function(){
  //         window.location.href=TIMEZONE_REDIRECTION_URL
  //       })
  //     }
  // },[])


  useEffect(() => {
    storageHost = createHost([
      {
        origin: LOGIN_DOMAIN,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: EXPENDITURE_UI_URL,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: BUDGET_UI_URL,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: PAYROLL_UI_URL,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: HRMS_UI_URL,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: BUDGET_PLANNING_UI_URL,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: ESS_UI_URL,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: ESR_UI_URL,
        allowedMethods: ['get', 'set', 'remove'],
      },

      {
        origin: DCM_UI_URL,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: WORKS_UI_URL,
        allowedMethods: ['get', 'set', 'remove'],
      },
    ]);
  }, [])

  const formik = useFormik({
    initialValues: intialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      localStorage.removeItem("token");
      localStorage.removeItem("serviceList");
      localStorage.clear();
      localStorage.setItem('userId', values.username)
      values.username = values.username.trim()
      values.password = values.password.trim()
      CommonAjaxPostLoginRequest(AUTHENTICATION_URL, values, dispatch).then((response) => {
        if (response !== "" && response !== null && response !== undefined) {
          localStorage.setItem("token", JSON.stringify(response.access_token));
          localStorage.setItem("refresh_token", JSON.stringify(response.refresh_token));
          localStorage.setItem('userData', JSON.stringify(response))
          localStorage.setItem('userName', JSON.stringify(values.username))
          mainAppguest = createGuest(LOGIN_DOMAIN);
          let displayName = ""
          const base64Url = response?.access_token.split('.')[1];
          if (base64Url !== undefined) {
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            displayName = JSON.parse(window.atob(base64)).name
            localStorage.setItem('displayName', JSON.stringify(displayName))
          }
          mainAppguest.set('token', JSON.stringify(response.access_token), function (error, data) {
            // foo is now set to 'bar'
          });
          mainAppguest.set('displayName', JSON.stringify(displayName), function (error, data) {
            // foo is now set to 'bar'
          });
          mainAppguest.set('userData', JSON.stringify(response), function (error, data) {
            // foo is now set to 'bar'
          });
          mainAppguest.set('refresh_token', JSON.stringify(response.refresh_token), function (error, data) {
            // foo is now set to 'bar'
          });
          mainAppguest.set('userId', JSON.stringify(values.username), function (error, data) {
            // foo is now set to 'bar'
          });
          history.push("/dashboard");
        }
        else {
          dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });
          setShowErrorMsg(true);
          formik.setFieldValue('username', '');
          formik.setFieldValue('password', '');
        }
      });
    }
  });
  function knowYourChallan() {
    history.push('/knowYourCfmsId')
  }
  function forgotPassword() {
    history.push("/forgotPassword");
  }
  function gos() {
    history.push("/gos");
  }
  function newAlerts() {
    history.push("/budgetRelatedPdfDetails");
  }
  function usermanuals() {
    history.push("/usermanuals")
  }
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  return (
    <>
      <FormikProvider value={formik}>
        <HERBUI.Navbar collapseOnSelect expand="lg" className="HERB-Top-Nav" variant="dark">
          <HERBUI.Container>
            <HERBUI.Navbar.Brand href="/login">
              <img
                src="img/herb.svg"
                width="250"
                height="45"
                className="d-inline-block align-top"
                alt="HERB"
              />
            </HERBUI.Navbar.Brand>
            <HERBUI.Navbar.Toggle className="bg-secondary" aria-controls="responsive-navbar-nav" />
            <HERBUI.Navbar.Collapse id="responsive-navbar-nav">
              <HERBUI.Nav className="me-auto"></HERBUI.Nav>
              <HERBUI.Nav>

                {/* <HERBUI.Nav.Link onClick={() => knowYourChallan()} className="nav_link1">
                  <b  >Know Your CFMS ID</b>
                </HERBUI.Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <HERBUI.Nav.Link onClick={() => gos()} className="nav_link1">
                  <b  >GOs, Circulars, Memos</b>
                </HERBUI.Nav.Link>
                <HERBUI.Nav.Link onClick={() => newAlerts()} className="nav_link1">
                  <b>Budget 2023-24<img src="../img/gif_new.gif" alt="new" /></b>

                </HERBUI.Nav.Link> */}
              </HERBUI.Nav>
            </HERBUI.Navbar.Collapse>
          </HERBUI.Container>
        </HERBUI.Navbar>
        <HERBUI.Navbar collapseOnSelect expand="lg" variant="dark">
          <HERBUI.Container style={{ marginLeft: '0px' }}>
            {/* <HERBUI.Navbar.Brand href="/login">
              <img
                src="img/herb.svg"
                width="250"
                height="45"
                className="d-inline-block align-top"
                alt="HERB"
              />
            </HERBUI.Navbar.Brand> */}
            {/* <HERBUI.Navbar.Toggle className="bg-secondary" aria-controls="responsive-navbar-nav" />
            <HERBUI.Navbar.Collapse id="responsive-navbar-nav">
              <HERBUI.Nav className="me-auto"></HERBUI.Nav>
              <HERBUI.Nav>

                <HERBUI.Nav.Link onClick={() => knowYourChallan()} className="nav_link1">
                  <b  >Know Your CFMS ID</b>
                </HERBUI.Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;
                <HERBUI.Nav.Link className="nav_link1" href={'https://appstore.herb.apcfss.in/iOSHerb.html'} >
                  <b>HERB Mobile App</b>
                </HERBUI.Nav.Link>
                <HERBUI.Nav.Link onClick={() => newAlerts()} className="nav_link1">
                  <b>AP Budget 2023-24 Mobile App<img src="../img/gif_new.gif" alt="new" /></b>
                </HERBUI.Nav.Link>
                <HERBUI.Nav.Link onClick={() => usermanuals()} className="nav_link1">
                  <b>User Manuals<img src="../img/gif_new.gif" alt="new" /></b>
                </HERBUI.Nav.Link>
                <HERBUI.Nav.Link onClick={() => gos()} className="nav_link1">
                  <b  >GOs, Circulars, Memos</b>
                </HERBUI.Nav.Link>
                <HERBUI.Nav.Link href={'https://apfinance.gov.in/Budget2324/'} className="nav_link1">
                  <b>AP Budget 2023-24<img src="../img/gif_new.gif" alt="new" /></b>
                </HERBUI.Nav.Link>
              </HERBUI.Nav>
            </HERBUI.Navbar.Collapse> */}
          </HERBUI.Container>
        </HERBUI.Navbar>
        <HERBUI.Row style={{ marginBottom: '50px' }}>
          <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} />
          <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} className="  " >
            <img src="img/corner.svg" className="corner" alt="dots" />
            <div className="main_form">
              <HERBUI.Container>
                <HERBUI.Row>
                </HERBUI.Row>
              </HERBUI.Container>
              <form className="form_adjust" style={{ marginTop: '25%' }} onSubmit={formik.handleSubmit}>
                <div className="inner-herbpage-service-title-sub-np">
                  <h1 style={{ fontSize: '22px' }}> Login </h1>
                </div>
                <HERBUI.InputGroup className="mb-3">
                  <HERBUI.InputGroup.Text id="basic-addon1" className="group_txt">
                    <BiUser className="font_size" />
                  </HERBUI.InputGroup.Text>
                  <Field name="username" placeholder="User ID" type="text"
                    aria-label="User ID" autoComplete={"off"}
                    aria-describedby="basic-addon1"
                    className="form-control" />
                  <ErrorMessage name="username" className="text-error" component="div" />
                </HERBUI.InputGroup>
                <HERBUI.InputGroup className="mb-3">
                  <HERBUI.InputGroup.Text id="basic-addon2" className="group_txt">
                    <BiLock className="font_size" />
                  </HERBUI.InputGroup.Text>
                  <Field placeholder="Password" name="password" type={passwordType}
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                    className="form-control" autoComplete="off" />
                  <HERBUI.InputGroup.Text id="basic-addon2" className="group_txt">
                      {passwordType === "password" ? <FaEyeSlash onClick={togglePassword}/> : <FaEye onClick={togglePassword}/>}
                  </HERBUI.InputGroup.Text>
                  <ErrorMessage name="password" className="text-error" component="div" />
                </HERBUI.InputGroup>
                <div className="d-grid gap-2">
                  <HERBUI.Button variant="primary" type="submit" className="btn_primary">
                    Sign in
                  </HERBUI.Button>
                  <a className="text-end forgot mb-4" onClick={() => forgotPassword()}>Forgot Password ?</a>
                </div>
                {showErrorMsg &&
                  <div className="row">
                    <div className="col-12  col-sm-12 col-md-12 col-xl-12 col-lg-12">
                      <div className="  alert alert-danger alert-dismissible fade show" role="alert">

                        <strong>Invalid Username/Password </strong>
                        <button type="button" onClick={() => setShowErrorMsg(false)} className="btn btn-close" data-dismiss="alert"></button>
                      </div>
                    </div>

                  </div>
                }
              </form>
            </div>

            {/* <HERBUI.Row>
              <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mx-5>
                <a className="text-light" href={'https://appstore.herb.apcfss.in/iOSHerb.html'} target="_blank" rel="noreferrer">
                  <div className="text-center  mx-5 mb-2 bg-primary p-3"> <AiOutlineMobile className="landing-icon-for-demo text-light" />
                    Download Employee Mobile APP (HERB)
                  </div>
                </a>
              </HERBUI.Col>
            </HERBUI.Row> */}
            {/* <div className='alert alert-info mx-5' style={{ backgroundColor: "#f3f3f3", color: "#000000", borderColor: "#ddd" }}>
              <span className='mb-1'>
                <ol className='mt-2'>
                  <li><small> <a href={UserManualDDO1} target="_blank" rel="noreferrer">User Manual to Upload Details of Works Recorded but not Uploaded in CFMS </a><img src="../img/gif_new.gif" alt="new" /> </small></li>
                </ol>
              </span>

            </div>
            <div className='alert alert-info mx-5' style={{ backgroundColor: "#f3f3f3", color: "#000000", borderColor: "#ddd" }}>
              <b>APFRS MOBILE APP :  </b><br />
              <span className='mb-1'>
                <ol className='mt-2'>
                  <li><small>  <a href={assignLocation} target="_blank" rel="noreferrer">User Manual for DDO's to Assign Locations</a> </small></li>
                  <li><small> <a href={mobileNumberUpdation} target="_blank" rel="noreferrer">User Manual for DDO's to Employee Mobile Number Updation</a> </small></li>
                </ol>
              </span>

            </div>
            <div className='alert alert-info mx-5' style={{ backgroundColor: "#f3f3f3", color: "#000000", borderColor: "#ddd" }}>
              <b>Note:  </b><br />
              <span className='mb-1'>
                <ol className='mt-2'>
                  <li><small> Monthly regular salary bill submissions (including Outsourced in APCOS portal) are allowed from the 16th to the 25th as per schedule.</small></li>
                  <li><small>Bill Submission is not permitted after 25th.</small></li>
                </ol>
              </span>
            </div> */}
          </HERBUI.Col>
          <HERBUI.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} />
        </HERBUI.Row>



        <HERBUI.Container>
          <HERBUI.Row>
            <HERBUI.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: 'center', marginTop: '50px' }} >
              <HERBUI.Navbar className="border text-center">
                <HERBUI.Navbar.Toggle className="bg-secondary" aria-controls="responsive-navbar-nav" />
                <HERBUI.Navbar.Collapse id="responsive-navbar-nav">
                  {/* <HERBUI.Navbar collapseOnSelect expand="lg" className="HERB-Top-Nav" variant="dark"> */}
                  <HERBUI.Nav className="text-center">
                    <HERBUI.Nav.Link onClick={() => knowYourChallan()} className="nav_link1">
                      <b  >Know Your CFMS ID</b>
                    </HERBUI.Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;
                    <HERBUI.Nav.Link className="nav_link1" target="_blank" href={'https://appstore.herb.apcfss.in/iOSHerb.html'} >
                      <b>HERB Mobile App</b>
                    </HERBUI.Nav.Link>
                    <HERBUI.Nav.Link href={'https://appstore.herb.apcfss.in/iOSHerb.html'} target="_blank" className="nav_link1">
                      <b>AP Budget 2023-24 Mobile App<img src="../img/gif_new.gif" alt="new" /></b>
                    </HERBUI.Nav.Link>
                    <HERBUI.Nav.Link onClick={() => usermanuals()} className="nav_link1">
                      <b>User Manuals<img src="../img/gif_new.gif" alt="new" /></b>
                    </HERBUI.Nav.Link>
                    <HERBUI.Nav.Link onClick={() => gos()} className="nav_link1">
                      <b  >GOs, Circulars, Memos</b>
                    </HERBUI.Nav.Link>
                    <HERBUI.Nav.Link href={'https://apfinance.gov.in/Budget2324/'} target="_blank" className="nav_link1">
                      <b>AP Budget 2023-24<img src="../img/gif_new.gif" alt="new" /></b>
                    </HERBUI.Nav.Link>
                  </HERBUI.Nav>
                  {/* </HERBUI.Navbar> */}
                </HERBUI.Navbar.Collapse>
              </HERBUI.Navbar>
            </HERBUI.Col>
          </HERBUI.Row>
        </HERBUI.Container>
      </FormikProvider>
    </>
  );
}
export default Login;